/* Import AG Grid styles */
@import "~ag-grid-community/styles/ag-grid.css";

/* Define CSS custom properties */
/*:root {*/
/*    !*--ag-light: #fff; !* Example color, replace with actual value *!*!*/
/*    --text-color: #333; !* Example color, replace with actual value *!*/
/*    !*--odd-color: #f9f9f9; !* Example color, replace with actual value *!*!*/
/*    !*--border-color: #ccc; !* Example color, replace with actual value *!*!*/
/*    --ag-chip-background-color: rgba(24, 29, 31, 0.07); !* Example color, replace with actual value *!*/
/*    --ag-cell-horizontal-padding: 2px !important;*/
/*    --ag-background-color: --ag-light !important;*/
/*}*/

/* Customizing the Alpine theme using CSS variables */
.ag-theme-alpine {
    background-color: var(--ag-light);
    background: var(--ag-light);
    border-color: var(--border-color);
    font-family: 'Open Sans', sans-serif;
    --ag-cell-horizontal-padding: 3px;
    --ag-data-color:var(--text-color);
    color: var(--text-color) !important;
    --ag-header-background-color: var(--ag-light) ;
    --ag-background-color: var(--ag-light) ;
    --ag-foreground-color: var(--ag-light) ;
    border: none !important;
    /*--ag-light: #fff; !* Example color, replace with actual value *!*/

}

/* Customizing specific elements using CSS rules */
.ag-theme-alpine .ag-header-cell-label {
    background-color: var(--ag-light);
    color: var(--text-color);
    /*font-style: italic;*/
}

.ag-theme-alpine .ag-column-drop-horizontal,
.ag-theme-alpine .ag-column-drop-empty-message,
.ag-theme-alpine .ag-drag-handle,
.ag-theme-alpine .ag-column-drop-cell-button {
    background-color: var(--ag-light);
    color: var(--text-color);
    opacity: 0.75;
}

.ag-theme-alpine .ag-column-drop-cell {
    background: var(--ag-chip-background-color);
}

.ag-theme-alpine .ag-popup-editor {
    z-index: 999999;
}

/* Set cell horizontal padding */
.ag-theme-alpine .ag-cell {
    padding-left: 5px;
    padding-right:0;
    
}

.ag-header-cell, .ag-theme-alpine .ag-pinned-left-header, .ag-theme-alpine .ag-ltr .ag-cell {
    border-right: none !important;
    border: none !important;
}

.ag-ltr .ag-cell {
    border-right-width: 1px;
    /* border-right: none !important; */
}

.ag-header-cell, .ag-header-group-cell {
    padding-left: 3px;
    padding-right: 0px;
}

/* .ag-header-cell-label {
    padding-right: -13px;
} */

/* Set cell horizontal padding */
.ag-theme-alpine .ag-row {
    background-color: var(--ag-light);
    color: var(--text-color);
}


.ag-theme-alpine .ag-header-background-color {
    background-color: var(--ag-light);
}

/* Additional custom styles */
body.datta-dark .text-white {
    color: #ffffff !important;
}

.ag-theme-alpine .ag-theme-alpine-dark {
    background-color: var(--ag-dark);
}

.ag-large-text-input {
  z-index: 8000 !important;
}

.ag-text-area-input-wrapper{
    z-index: 8000 !important;
}

.ag-cell-label-container{
    background-color: var(--ag-light);
}