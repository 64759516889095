.sw-theme-dots {
    .sw-container {
        min-height: 300px;
    }
    .step-content {
        padding: 10px 0;
        border: none;
        background-color: #fff;
        text-align: left;
    }
    .sw-toolbar {
        background: #fff;
        border-radius: 0 !important;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0 !important;
    }
    .sw-toolbar-top {
        border-bottom-color: #ddd !important;
    }
    .sw-toolbar-bottom {
        border-top-color: #ddd !important;
        border-bottom-color: #ddd !important;
    }
    > ul.step-anchor {
        position: relative;
        background: #fff;
        border: 0 solid #ccc !important;
        list-style: none;
        &:before {
            content: ' ';
            position: absolute;
            top: 70px;
            bottom: 0;
            width: 100%;
            height: 5px;
            background-color: #f5f5f5;
            border-radius: 3px;
            z-order: 0;
            z-index: 95;
        }
        > li {
            border: none;
            > a {
                position: relative;
                text-align: center;
                font-weight: 700;
                background: 0 0;
                border: none;
                color: #ccc;
                text-decoration: none;
                outline-style: none;
                z-index: 96;
                display: block;
                &:before {
                    content: ' ';
                    position: absolute;
                    bottom: 2px;
                    left: 40%;
                    margin-top: 10px;
                    display: block;
                    border-radius: 50%;
                    color: #428bca;
                    background: #f5f5f5;
                    border: none;
                    width: 30px;
                    height: 30px;
                    text-decoration: none;
                    z-index: 98;
                }
                &:after {
                    content: ' ';
                    position: relative;
                    left: 43%;
                    bottom: 2px;
                    margin-top: 10px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    background: #f5f5f5;
                    border-radius: 50%;
                    z-index: 99;
                }
                &:hover {
                    color: #ccc;
                    background: 0 0;
                }
                &:focus {
                    color: #ccc;
                    border: none;
                }
            }
            &.clickable > a:hover {
                color: #999;
            }
            &.active > a {
                color: #5bc0de;
                &:hover {
                    border: none;
                }
                &:after {
                    background: #5bc0de;
                }
            }
            &.done > a {
                color: #5cb85c;
                &:after {
                    background: #5cb85c;
                }
            }
            &.danger > a {
                color: #d9534f;
                &:after {
                    background: #d9534f;
                }
            }
            &.disabled > a {
                color: #eee !important;
                &:hover {
                    color: #eee !important;
                }
                &:after {
                    background: #eee;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .sw-theme-dots > ul.step-anchor {
        &:before {
            top: 0;
            bottom: 0;
            left: 10px;
            width: 5px;
            height: 100%;
            background-color: #f5f5f5;
            display: block;
            margin-right: 10px;
        }
        > li {
            margin-left: 20px;
            display: block;
            clear: both;
            > a {
                text-align: left;
                margin-left: 0;
                display: block;
                &:before {
                    top: 5px;
                    left: -23px;
                    margin-right: 10px;
                    display: block;
                }
                &:after {
                    top: -38px;
                    left: -31px;
                    margin-right: 10px;
                    display: block;
                }
            }
        }
    }
}
