.masonry-container {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    p {
        text-align: center;
        padding: 4px;
        font-family: monospace;
        background: #222;
        color: #efefef;
    }
}
.masonry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    width: 100%;
    margin: auto;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    flex-grow: 1;
}
.tile {
    margin: 0.75rem;
    img {
        border-radius: calc(0.25rem - 1px);
        width: 100%;
    }
}
